import { useProjectLoad } from 'hooks/useProjectLoad';
import { useSave } from 'hooks/useSave';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilesState } from 'redux/reducers/files';
import { ProjectState } from 'redux/reducers/project';
import { fetchProject } from 'redux/reducers/project/projectReducer';
import workStepsSlice, { useWorkSteps } from 'redux/reducers/worksteps';
import { RootState, AppDispatch } from 'redux/store';
import { Status } from 'redux/types';
import { useActiveWorkstepActions } from 'redux/reducers/activeWorkStep/workstepActions';
import { Flex, Loader, toast } from '@cognite/cogs.js';
import { useProcedureFilesLoad } from 'hooks/useProcedureFilesLoad';
import { RouteComponentProps } from 'react-router';
import { SubHeader } from './components/SubHeader';
import { WorkstepsSidebar } from './components/Worksteps/WorkstepsList';
import { ExecuteProcedure } from './components/ExecuteProcedure/ExecuteProcedure';
import { PnidPreview } from './components/PnidPreview';
import { useProject } from '../../redux/reducers/project/hooks';

interface EditProcedureProps extends RouteComponentProps<{ id: string }> {}

const EditProcedure = ({ match, location }: EditProcedureProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [fileIds, setFileIds] = useState([] as number[]);
  const [savePreWorkItemsAllowed, setSavePreWorkItemsAllowed] = useState(false);
  const [startSaving, setStartSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [workStepsFileIds, setWorkStepsFileIds] = useState<number[]>([]);
  const [showWorkSteps, setShowWorkSteps] = useState(false);

  const {
    project,
    preWorkItems,
    newProject,
    passValves,
    status,
    isProcedureLoaded,
  } = useSelector<RootState, ProjectState>((state) => state.project);

  // (IP-84) logic for fetching file ID from store
  const { files } = useSelector<RootState, FilesState>((state) => state.files);

  const { savePreWorkItems, saveHandler } = useSave();
  const { workStepsLoaded, phases, fetchEventsBasedOnWorkSteps } =
    useWorkSteps();
  const { loadProcedure } = useProjectLoad();
  const { viewOnly } = useProject();

  const { getClassTypes } = useActiveWorkstepActions();
  const { clearConnectedFiles } = useProcedureFilesLoad();

  // Init component hook, use it if you want to
  // run something, before anything is shown
  useEffect(() => {
    if (!isInitialized) {
      clearConnectedFiles();
      getClassTypes();
      setIsInitialized(true);
    }
    // eslint-disable-next-line
  }, [isInitialized]);

  useEffect(() => {
    if (!project.id && match?.params?.id) {
      dispatch(fetchProject(match?.params.id));
    }
  }, [dispatch, project, match]);

  useEffect(() => {
    if (files.length && !fileIds.length) {
      const listOfFileIds = files.map((file) => file.id);
      setFileIds(listOfFileIds);
    }
  }, [files, fileIds, location]);

  useEffect(() => {
    // covers more cases
    // 1. Project could be passed from start page, but other data is not loaded
    // 2. Project is fully loaded, on edit page or back again you don't have to fetch again
    if (project.id && status === Status.success && !isProcedureLoaded) {
      loadProcedure(project, false);
    }
    // eslint-disable-next-line
  }, [dispatch, project.id, status, isProcedureLoaded]);

  useEffect(() => {
    dispatch(workStepsSlice.actions.setWorkstepsToViewed());
  }, [dispatch]);

  useEffect(() => {
    if (
      project.status === 'compilation' ||
      project.status === 'executionReady'
    ) {
      if (!savePreWorkItemsAllowed) {
        setSavePreWorkItemsAllowed(true);
      } else if (savePreWorkItemsAllowed) {
        savePreWorkItems();
      }
    }
    // if pre-work items are changed, trigger save
    // eslint-disable-next-line
  }, [preWorkItems]);

  useEffect(() => {
    if (phases?.length && workStepsLoaded) {
      if (phases?.length && !startSaving) {
        setStartSaving(true);
      } else if (phases?.length && startSaving) {
        saveHandler('edit procedure');
      }
    }

    // Want the hook to just run if phases / migration status changes
    // eslint-disable-next-line
  }, [phases, workStepsLoaded, project.needsMigration, project.pnIdsDrawings]);

  // use effect to calculate workStep extra info
  useEffect(() => {
    if (
      project.id &&
      status === Status.success &&
      isProcedureLoaded &&
      workStepsLoaded &&
      phases.length > 0
    ) {
      setTimeout(() => {
        fetchEventsBasedOnWorkSteps();
      }, 300);
    }
  }, [
    project.id,
    status,
    isProcedureLoaded,
    workStepsLoaded,
    phases,
    fetchEventsBasedOnWorkSteps,
  ]);

  // issue: if a procedure was loaded, without the work steps loaded and a save action was triggered => all current work steps where removed.
  if (!workStepsLoaded) {
    return <Loader />;
  }

  if (!isInitialized) {
    return <Loader />;
  }

  if (status === Status.failed) {
    toast.error(
      <div>
        <h3>We’re sorry</h3> We couldn’t find that procedure. Try finding it by
        searching for the asset instead.
      </div>
    );

    return <Loader />;
  }

  const currentViewOnlyMode = viewOnly();

  return (
    <div>
      {project.status !== 'execution' && (
        <SubHeader
          project={project}
          workStepsFileIds={workStepsFileIds}
          viewOnly={currentViewOnlyMode}
          showWorkSteps={showWorkSteps}
          setShowWorkSteps={setShowWorkSteps}
        />
      )}
      <Flex
        direction="row"
        style={{
          height:
            project.status === 'execution'
              ? '100vh'
              : 'calc(100vh - 54px - 45px)',
          position: 'absolute',
          display: 'flex',
          width: '100%',
        }}
      >
        {project.status && project.status !== 'execution' && (
          <WorkstepsSidebar
            projectStatus={project.status}
            viewOnly={currentViewOnlyMode}
          />
        )}
        <PnidPreview
          newProject={newProject}
          fileIds={fileIds}
          workStepsFileIds={workStepsFileIds}
          setWorkStepsFileIds={setWorkStepsFileIds}
          viewOnly={currentViewOnlyMode}
          showWorkSteps={showWorkSteps}
        />
      </Flex>

      {project.status === 'execution' ? (
        <ExecuteProcedure
          project={project}
          passValves={passValves}
          viewOnly={currentViewOnlyMode}
        />
      ) : null}
    </div>
  );
};

export default EditProcedure;
